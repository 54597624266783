import {useState, useEffect} from 'react';
import {db} from './../firebase/firebaseConfig'
import {useHistory} from 'react-router-dom'

const useObtenerProducto = (id) => {
    const [producto, establecerProducto] = useState(null)
    const history = useHistory()

    useEffect(()=> {
        db.collection('productos').doc(id).get()
        .then((doc) => {
            if(doc.exists){
                establecerProducto(doc.data())
            }else{
                history.push('/admin/inventario')
            }
        })

    }, [history, id])

    return [producto]
}
 
export default useObtenerProducto;