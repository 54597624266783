import React, {useState, useEffect, useMemo, useRef} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useObtenerProducto from '../hooks/useObtenerProducto';
import Alerta from '../elementos/Alerta';
import editarProducto from '../firebase/editarProducto';
import { storage } from '../firebase/firebaseConfig';
import formatearCantidad from '../funciones/convertirAMoneda'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import defaultImage from './../imagenes/default.jpg';
import styled from 'styled-components';
import useGetGallery from '../hooks/useGetGallery';
import eliminarProducto from '../firebase/eliminarProducto';

const EditarProducto = () => {
    const MySwal = withReactContent(Swal)
    const loader = withReactContent(Swal)
    const {id} = useParams()
    const [producto] = useObtenerProducto(id)
    const history = useHistory()
    const [inputNombre, cambiarInputNombre] = useState('')
    const [inputPrecioVenta, cambiarInputPrecioVenta] = useState('')
    const [inputPrecioVentaOferta, cambiarInputPrecioVentaOferta] = useState('')
    const [inputDescripcion, cambiarInputDescripcion] = useState('')
    const [inputDescripcionCompleta, cambiarInputDescripcionCompleta] = useState('')
    const [tags, setTags] = useState('')
    const [imagenes, setImagenes] = useState(null)
    const [isActive, setIsActive] = useState(true)
    const [alerta, cambiarAlerta] = useState({})
    const [estadoAlerta, cambiarEstadoAlerta] = useState(false)
    const [cargando, cambiarCargando] = useState(false)

    useEffect(()=>{
        if(producto){
            cambiarInputNombre(producto.nombre)
            cambiarInputPrecioVenta(producto.precioVenta.normal)
            cambiarInputDescripcion(producto.descripcion.corta)
            cambiarInputPrecioVentaOferta(producto.precioVenta.oferta || '')
            cambiarInputDescripcionCompleta(producto.descripcion.completa || '')
            setImagenes(producto.imagenes || [])
            setTags(producto.tags.join(', '))
            setIsActive(producto.isActive)
        }
    },[producto])
    
    const handleChange = (e) => {
        const handlers = {
            nombre: val => cambiarInputNombre(val),
            precioVenta: val => cambiarInputPrecioVenta(val.replace(/[^0-9.]/g, '')),
            precioVentaOferta: val => cambiarInputPrecioVentaOferta(val.replace(/[^0-9.]/g, '')),
            descripcion: val => cambiarInputDescripcion(val),
            descripcionCompleta: val => cambiarInputDescripcionCompleta(val),
            tags: val => setTags(val)
        }
        handlers[e.target.name](e.target.value)
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        let hayError = false

        const campos = {
            nombre: 'Nombre',
            cantidad: 'Cantidad',
            precioUnitario: 'Precio unitario',
            precioVenta: 'Precio venta',
            descripcion: 'Descripcion',
        }

        const productoEditado = {
            nombre: inputNombre,
            precioVenta: inputPrecioVenta,
            descripcion: inputDescripcion
        }

        for (const clave in productoEditado) {
            if((productoEditado[clave] === '' || productoEditado[clave] <= 0) && clave !== 'descripcion'){
                hayError = true
                cambiarEstadoAlerta(true)
                cambiarAlerta({tipo: 'error', mensaje: `Campo "${campos[clave]}" invalido`})
            }
        }

        if(!hayError){
            cambiarCargando(true)
             
            MySwal.fire({
                title: <p>Editar producto</p>,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#157347',
                cancelButtonColor: '#bb2d3b',
                confirmButtonText: 'Ok!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if(result.isConfirmed){
                    loader.fire({
                        title: <p>Actualizando producto</p>,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        backdrop: true,
                        didOpen: () => {
                            loader.showLoading()
                        }
                    })

                    saveData(productoEditado)
                }
            })
        }
    }
    
    const saveData = (productoEditado) =>{
        editarProducto({
            id,
            ...productoEditado,
            precioVenta: {
                normal: parseFloat(inputPrecioVenta),
                oferta: parseFloat(inputPrecioVentaOferta) || null
            },
            descripcion: {
                corta: inputDescripcion,
                completa: inputDescripcionCompleta
            },
            imagenes,
            tags: tags.split(', '),
            isActive
        })
        .then(()=> {
            loader.hideLoading()
            loader.close()
            MySwal.fire({
                html: <p>Producto actualizado!</p>,
                timer: 2000,
                icon: 'success',
                showConfirmButton: false,
                didDestroy: ()=>{
                    history.push('/admin/inventario')
                }
            })
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const handleDelete = () => {
        MySwal.fire({
            title: <p>Eliminar producto</p>,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#157347',
            cancelButtonColor: '#bb2d3b',
            confirmButtonText: 'Ok!',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if(result.isConfirmed){
                loader.fire({
                    title: <p>Eliminando producto</p>,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    backdrop: true,
                    didOpen: () => {
                        loader.showLoading()
                    }
                })

                try {
                    await eliminarProducto(id)
                    loader.hideLoading()
                    loader.close()
                    MySwal.fire({
                        html: <p>Producto eliminado!</p>,
                        timer: 2000,
                        icon: 'success',
                        showConfirmButton: false,
                        didDestroy: ()=>{
                            history.push('/admin/inventario')
                        }
                    })
                } catch (error) {
                    console.log(error)
                }
            }
        })
    }

    if(!producto) return null
    return ( 
        <> 
            <div className="row">
                <div className="col col-sm-8 col-xl-6 mx-auto">
                    <h1 className="text-center mb-5">Editar producto</h1>
                    {
                        producto && 
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-3">
                                <div className="col col-6">
                                    {
                                        imagenes && 
                                            <ImagenesProducto imagenes={imagenes} setImagenes={setImagenes} />
                                    }
                                </div>
                                <div className="col col-6">
                                    <div className="form-floating mb-1">
                                        <input
                                            type="text"
                                            disabled={true}
                                            className="form-control"
                                            placeholder="Cantidad"
                                            value={producto.cantidad}
                                        />
                                        <label>Cantidad</label>
                                    </div>
                                    <div className="form-floating mb-1">
                                        <input
                                            type="text"
                                            disabled={true}
                                            className="form-control"
                                            placeholder="Precio unitario"
                                            value={formatearCantidad(producto.precioUnitario)}
                                        />
                                        <label>Precio unitario</label>
                                    </div>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            disabled={true}
                                            className="form-control"
                                            placeholder="Costo unitario"
                                            value={formatearCantidad(producto.costoUnitario)}
                                        />
                                        <label>Costo unitario</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre"
                                    name="nombre"
                                    value={inputNombre}
                                    onChange={handleChange}
                                />
                                <label>Nombre</label>
                            </div>
                            <div className="row mb-3">
                                <div className="col col-6">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Precio venta"
                                            name="precioVenta"
                                            value={inputPrecioVenta}
                                            onChange={handleChange}
                                        />
                                        <label>Precio venta</label>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Precio especial"
                                            name="precioVentaOferta"
                                            value={inputPrecioVentaOferta}
                                            onChange={handleChange}
                                        />
                                        <label>Precio especial</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Descripción"
                                    name="descripcion"
                                    value={inputDescripcion}
                                    onChange={handleChange}
                                />
                                <label>Descripción</label>
                            </div>
                            <div className="form-floating mb-3">
                                <textarea
                                    style={{ height: 'auto' }}
                                    rows='6'
                                    className="form-control"
                                    placeholder="Descripción completa"
                                    name="descripcionCompleta"
                                    value={inputDescripcionCompleta}
                                    onChange={handleChange}
                                />
                                <label>Descripción completa</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Etiquetas"
                                    name="tags"
                                    title='use comas para separar las etiquetas'
                                    value={tags}
                                    onChange={handleChange}
                                />
                                <label>Etiquetas*</label>
                            </div>
                            <div className="mb-3">
                                <input
                                    type="checkbox"
                                    name="isActive"
                                    id="isActive"
                                    title='Activar para mostrar en el catalogo'
                                    value={isActive}
                                    checked={isActive}
                                    onChange={() => setIsActive(!isActive)}
                                    className='me-2'
                                />
                                <label htmlFor='isActive' title='Activar para mostrar en el catalogo'>Activo*</label>
                            </div>

                            <div className="d-grid">
                                <button disabled={cargando} type="submit" className="btn p-3 btn-dark fw-bold mb-3">
                                    {
                                        !cargando && 'GUARDAR'
                                    }
                                    {
                                        cargando && 'GUARDANDO'
                                    }
                                </button>
                                {
                                    producto.cantidad === 0 &&
                                        <button onClick={handleDelete} type='button' className="btn btn-link text-danger">ELIMINAR</button>
                                }
                            </div>
                        </form>
                    }
                </div>
            </div>

            <Alerta
                tipo={alerta.tipo}
                mensaje={alerta.mensaje}
                estadoAlerta={estadoAlerta}
                cambiarEstadoAlerta={cambiarEstadoAlerta}
            />
        </>
    );
}

const ImagenesProducto = ({ imagenes = [], setImagenes }) => {
    const [current, setCurrent] = useState({
        position: 0,
        src: imagenes[0]
    })
    const gallery = useGetGallery()
    const [galleryImages, setGalleryImages] = useState([])
    const refModalGallery = useRef()
    const modalGallery = useMemo(() => {
        if (!refModalGallery.current) return null
        const modal = new window.bootstrap.Modal(refModalGallery.current)
        return modal
    }, [refModalGallery.current])
    const refModalImages = useRef()
    const modalImages = useMemo(() => {
        if (!refModalImages.current) return null
        const modal = new window.bootstrap.Modal(refModalImages.current)
        return modal
    }, [refModalImages.current])

    useEffect(() => {
        setGalleryImages(gallery)
    }, [gallery])

    const handleDelete = (position) => {
        Swal.fire({
            icon: 'question',
            title: 'Borrar imagen',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            showCancelButton: true
        }).then(result => {
            if (result.isConfirmed) {
                const newImages = [...imagenes]
                newImages.splice(position, 1)
                if (newImages.length === 0) {
                    setCurrent({})
                } else {
                    setCurrent({
                        position: 0,
                        src: newImages[0]
                    })
                }
                setImagenes(newImages)
            }
        })
    }

    const handleAddImage = (url) => {
        if (imagenes.length === 0) {
            setCurrent({
                position: 0,
                src: url
            })
        }
        setImagenes(imagenes.concat(url))
        modalGallery.hide()
        modalImages.show()
    }

    const handleUpload = (e) => {
        const file = e.target.files[0]
        if (!file) return
        console.log(file)
        const storageRef = storage.ref()
        const pictureRef = storageRef.child(`img/${file.name}`)
        const task = pictureRef.put(file)
        
        task.then((snapshot)=>{
            snapshot.ref.getDownloadURL().then(downloadURL => {
                handleAddImage(downloadURL)
                setGalleryImages(state => [...state, downloadURL])
            })
        },(error)=>{
            console.log(error.message)
        })
    }

    return (
        <>
            <div className="row" style={{ height: '250px' }}>
                <ScrollImages className="col col2 p-0">
                    {
                        imagenes.map((imagen, i) => (
                            <div key={i} className={`row image ${current.position === i ? 'active' : ''}`} onClick={() => setCurrent({ position: i, src: imagen })}>
                                <img style={{height: '100%',objectFit: 'cover',cursor: 'pointer'}} src={imagen} className="img-fluid" alt="imagen"></img>
                            </div>
                        ))
                    }
                </ScrollImages>
                <div className="col col-10" data-bs-toggle="modal" data-bs-target="#modalImagenes">
                    <img style={{height: '100%',objectFit: 'cover',cursor: 'pointer'}} src={current.src || defaultImage} className="img-fluid" alt="imagen"></img>
                </div>
            </div>

            <div ref={refModalImages} className="modal fade" id="modalImagenes" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Imagenes</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                {
                                    imagenes.map((imagen, i) => (
                                        <ImagenItem key={i} className="col col-4" onClick={() => handleDelete(i)}>
                                            <h6 className='text-center mb-0'>{i+1}</h6>
                                            <img src={imagen} className="img-fluid" alt="imagen" />
                                        </ImagenItem>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalGallery">Agregar imagen</button>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={refModalGallery} className="modal fade" id="modalGallery" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Galeria</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                {
                                    galleryImages.map((image, i) => (
                                        <div key={i} onClick={() => handleAddImage(image)} className="col col-3" style={{ cursor: 'pointer' }}>
                                            <img src={image} className="img-fluid" alt="imagen" />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalImagenes">Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={() => document.querySelector('#inputImagen').click()}>Subir desde dispositivo</button>
                        </div>
                    </div>
                </div>
            </div>

            <input
                hidden={true}
                type="file"
                id="inputImagen"
                accept='image/*'
                onChange={handleUpload}
            />
        </>
    )
}

const ScrollImages = styled.div`
    ::-webkit-scrollbar {
    width: 2px;
    }
    border-right: 1px solid #ccc;
    overflow: auto;
    height: 100%;

    .image {
        filter: grayscale(1);
        &.active {
            filter: grayscale(0);
        }
    }
`

const ImagenItem = styled.div`
    position: relative;
    cursor: pointer;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    &:hover::after {
        background: rgb(255 67 67 / 40%);
    }
`

export default EditarProducto;