import {db} from './firebaseConfig'
import socios from './../socios'
const sociosObjeto = socios.reduce((objeto,socio) => {
    return {
        ...objeto,
        [socio]: socio
    }
}, {})

const registrarVenta = ({id, producto, nota, productoCantidad, costoUnitario, cantidad, fecha, precio, balancesCapital, balancesSaldo, porcentajes, vendedores}) => {
    return new Promise(async (resolve, reject) => {
        try{
            let numeroVendedores = 0
            let personas = ''
            for (const vendedor in vendedores){
                if(vendedores[vendedor] && porcentajes[vendedor] > 0){
                    numeroVendedores++
                    personas += vendedor + ' '
                }
            }
            await db.collection('ventas').add({
                producto,
                cantidad,
                precio,
                nota,
                costoUnitario,
                vendedores: personas.trim(),
                fecha
            })
            if((productoCantidad - cantidad) === 0){
                await db.collection('productos').doc(id).update({ cantidad: 0, inStock: false })
            }else{
                await db.collection('productos').doc(id).update({
                    cantidad: productoCantidad - cantidad
                })
            }
            await db.collection('balances-capital').add({
                ...balancesCapital,
                stock: balancesCapital.stock - (cantidad * costoUnitario),
                cash: balancesCapital.cash + (cantidad * costoUnitario),
                fecha
            })
            
            const recompensa = 0.24
            const gananciaBase = (precio - costoUnitario) * (1-recompensa) * cantidad
            const recompensaVenta = (precio - costoUnitario) * recompensa * cantidad
            const nuevoBalancesSaldo= {}

            for(let socio in sociosObjeto){
                let fondeo = gananciaBase * porcentajes[socio]
                if(vendedores[socio]){
                    fondeo += recompensaVenta / numeroVendedores
                }
                if(porcentajes[socio] > 0){
                    await db.collection('fondeos-saldo').add({
                        descripcion: `Ganancia por venta. ${cantidad} ${producto}`,
                        socio,
                        cantidad: fondeo,
                        fecha
                    })
                    nuevoBalancesSaldo[socio] = balancesSaldo[socio] + fondeo
                }
            }
            
            await db.collection('balances-saldo').add({
                ...balancesSaldo,
                ...nuevoBalancesSaldo,
                fecha
            })
            resolve()
        }catch(error){
            reject(error)
        }
    })
}

export default registrarVenta