import { auth } from './firebase/firebaseConfig';
import {
  Switch,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import HistorialDeVentas from './componentes/HistorialDeVentas';
import Inventario from './componentes/Inventario';
import Dashboard from './componentes/Dashboard';
import logo from './imagenes/logo_foxinn.jpg';
import Gastos from './pages/gastos/Gastos';
import Retiros from './pages/retiros/Retiros';
import Fondeos from './pages/fondeos/Fondeos';

const App = () => {
  let { path, url } = useRouteMatch();
	const history = useHistory();

  const cerrarSesion = async () => {
		try {
			await auth.signOut();
			history.push('/');
		} catch(error) {
			console.log(error);
		}
	}

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark mb-3 p-2 sticky-top">
        <NavLink to={path} className="navbar-brand">
          <img width="50" src={logo} alt="logo" />
        </NavLink>
          <div className="navbar-nav">
            <NavLink exact to={path} className="nav-link" activeClassName="active">Inicio</NavLink>
            <div className="menu">
              <NavLink to={`${url}/inventario`} className="nav-link" activeClassName="active">Inventario</NavLink>
              <div className="submenu">
                <NavLink to={`${url}/inventario/registrar`} className="nav-link bg-dark">Registrar lote</NavLink>
              </div>
            </div>
            <NavLink to={`${url}/ventas`} className="nav-link" activeClassName="active">Ventas</NavLink>
            <div className="menu">
              <NavLink to={`${url}/gastos`} className="nav-link" activeClassName="active">Gastos</NavLink>
              <div className="submenu">
                <NavLink to={`${url}/gastos/registrar`} className="nav-link bg-dark">Registrar gasto</NavLink>
              </div>
            </div>
            <div className="menu">
              <NavLink to={`${url}/retiros`} className="nav-link" activeClassName="active">Retiros</NavLink>
              <div className="submenu">
                <NavLink to={`${url}/retiros/registrar`} className="nav-link bg-dark">Registrar retiro</NavLink>
              </div>
            </div>
            <div className="menu">
              <NavLink to={`${url}/fondeos`} className="nav-link" activeClassName="active">Fondeos</NavLink>
              <div className="submenu">
                <NavLink to={`${url}/fondeos/registrar`} className="nav-link bg-dark">Registrar fondeo</NavLink>
              </div>
            </div>
            <Link as="button" to="" className="nav-link ms-auto" onClick={cerrarSesion}>
              Salir <i className="bi bi-box-arrow-right"></i>
            </Link>
          </div>
      </nav>

        <Switch>
          <Route exact path={path} component={Dashboard} />
          <Route path={`${url}/fondeos`} component={Fondeos} />
          <Route path={`${url}/retiros`} component={Retiros} />
          <Route path={`${url}/gastos`} component={Gastos} />
          <Route path={`${url}/inventario`} component={Inventario} />
          <Route path={`${url}/ventas`} component={HistorialDeVentas} />
        </Switch>
    </>
  );
}


 
export default App;