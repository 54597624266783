import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import FormularioLote from './FormularioLote';
import ListaProductos from './ListaProductos';
import EditarProducto from './EditarProducto';
import RegistrarVenta from './RegistrarVenta';

const Inventario = () => {
  let { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ListaProductos} />
      <Route path={`${url}/registrar`} component={FormularioLote} />
      <Route path={`${url}/editar/:id`} component={EditarProducto} />
      <Route path={`${url}/vender/:id`} component={RegistrarVenta} />
    </Switch>
  );
}

 
export default Inventario;