import socios from "../socios"

const calcularAportes = ({cantidad, balancesSaldo}) => {
    let seNecesita = parseFloat(cantidad)
    let aportes = socios.reduce((objeto, socio) => {
        return {
            ...objeto,
            [socio]: 0
        }
    }, {
        total: 0
    })
    
    let saldo = JSON.parse(JSON.stringify(balancesSaldo))

    while(seNecesita > 0){
        let sociosQueAportan = 0
        socios.forEach(socio => {
            if(saldo[socio] > 0){
                sociosQueAportan++
            }
        })
        
        const divicion = seNecesita / sociosQueAportan
        for(let i=0; i<socios.length; i++){
            const socio = socios[i]
            if(saldo[socio] > 0){
                const seRestaDeSaldo = divicion >= saldo[socio]? saldo[socio] : divicion
                aportes[socio] += seRestaDeSaldo 
                saldo[socio] -= seRestaDeSaldo
    
                aportes.total += seRestaDeSaldo
                seNecesita -= seRestaDeSaldo
            }
        }
    }
    return aportes
}

export default calcularAportes;
