import {db} from './firebaseConfig'

const editarProducto = ({id, precioVenta, descripcion, nombre, imagenes, tags, isActive}) => {
    return db.collection('productos').doc(id).update({
        nombre,
        precioVenta,
        descripcion,
        imagenes,
        tags,
        isActive
    })
}

export default editarProducto