import CardProduct from "./components/CardProduct";
import Header from "./components/Header";
import Tags from "./components/Tags";
import SearchBar from "./components/SearchBar";
import { useParams, Link, useHistory } from "react-router-dom";
import useObtenerProductos from './../../hooks/useObtenerProductos'
import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";

const Catalogo = () => {
  const [showing, setShowing] = useState([])
  const productos = useObtenerProductos()
  const { tag } = useParams()
  const { location } = useHistory()

  const onSearch = useCallback((keywords) => {
    if (!keywords) {
      setShowing(productos)
      return
    }
    const filter = productos.filter(p => {
      const intoName = removeAccents(p.nombre).toLowerCase().includes(removeAccents(keywords))
      const intoTags = p.tags?.some(tag => removeAccents(keywords).includes(removeAccents(tag).toLowerCase().trim()))
      return intoName || intoTags
    })
    setShowing(filter)
  }, [productos])
  
  useEffect(() => {
    if (!productos) return
    if (tag) {
      onSearch(tag)
    } else if (location.search) {
      const search = location.search.replace('?search=', '')
      onSearch(search)
    } else {
      setShowing(productos)
    }
  }, [tag, onSearch, productos, location])

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  }

  return (
    <Page>
      <Header />
      <div className="mb-3">
        <SearchBar onSearch={onSearch} />
      </div>
      <Tags />
      <div className="row">
        {
          showing && showing.filter(p => p.isActive).map(product => (
            <div className="col-6 mb-4" key={product.id}>
              <Link to={`/producto/${product.id}`} style={{ color: 'initial', textDecoration: 'none' }}>
                <CardProduct producto={product} />
              </Link>
            </div>
          ))
        }
      </div>
    </Page>
  )
}
 
const Page = styled.div`
  max-width: 500px;
  margin: auto;
`
export default Catalogo;