import {db} from './firebaseConfig'
import socios from '../socios'

const registrarRetiroSaldo = ({socio, descripcion, cantidad, fecha, balancesSaldo, balancesCapital, porcentajes}) => {
    return new Promise(async (resolve, reject) => {
        try{
            if(balancesSaldo[socio] > 0){
                const restarDeSaldo = parseFloat(cantidad) <= balancesSaldo[socio]? parseFloat(cantidad) : balancesSaldo[socio]
                await db.collection('retiros-saldo').add({
                    descripcion,
                    socio,
                    cantidad: restarDeSaldo,
                    fecha
                })
                await db.collection('balances-saldo').add({
                    ...balancesSaldo,
                    [socio]: balancesSaldo[socio] - restarDeSaldo,
                    fecha
                })
            }

            if(parseFloat(cantidad) > balancesSaldo[socio]){
                const restarDeCapital = parseFloat(cantidad) - balancesSaldo[socio]
                const restarAlSocio = balancesCapital[socio] * (restarDeCapital / ((balancesCapital.cash + balancesCapital.stock) * porcentajes[socio]))
                await db.collection('retiros-capital').add({
                    descripcion,
                    socio,
                    cantidad: restarAlSocio,
                    fecha
                })
                await db.collection('balances-capital').add({
                    ...balancesCapital,
                    [socio]: balancesCapital[socio] - restarAlSocio,
                    cash: balancesCapital.cash - restarDeCapital,
                    fecha
                })
                // actualizar porcentajes
                let capitalTotal = restarAlSocio * -1
                socios.forEach(socio => {
                    capitalTotal+= balancesCapital[socio]
                })
                const nuevosPorcentajes = socios.reduce((objeto,socioCiclo) => {
                    const restar = socio === socioCiclo? restarAlSocio : 0
                    return {
                        ...objeto,
                        [socioCiclo]: (balancesCapital[socioCiclo] - restar) / capitalTotal
                    }
                }, {fecha})
                await db.collection('porcentajes').add(nuevosPorcentajes)
            }

            resolve()
        }catch(error){
            reject(error)
        }
    })
}

export default registrarRetiroSaldo