import {db} from './firebaseConfig'
import calcularAportes from '../funciones/calcularAportes'
import socios from './../socios'

const sociosObjeto = socios.reduce((objeto,socio) => {
    return {
        ...objeto,
        [socio]: socio
    }
}, {})

const registrarGasto = ({tipoGasto, descripcion, cantidad, fecha, balancesSaldo, balancesCapital}) => {
    return new Promise(async (resolve, reject) => {
        try{
            const gasto = {
                descripcion,
                tipoGasto,
                cantidad: parseFloat(cantidad),
                fecha
            }

            if(balancesCapital.cash >= gasto.cantidad){
                await db.collection('balances-capital').add({
                    ...balancesCapital,
                    cash: balancesCapital.cash - gasto.cantidad,
                    fecha
                })
            }else if(balancesCapital.cash === 0){
                // se toma de saldos
                const nuevoBalancesSaldo = {}
                const nuevoBalancesCapital = {}
                
                const aportes = calcularAportes({cantidad, balancesSaldo})

                for(let socio in sociosObjeto){
                    if(aportes[socio] > 0){
                        await db.collection('retiros-saldo').add({
                            descripcion: `gasto ${tipoGasto}. Por: ${gasto.cantidad}`,
                            socio,
                            cantidad: aportes[socio],
                            fecha
                        })
                        nuevoBalancesSaldo[socio] = balancesSaldo[socio] - aportes[socio]
                        nuevoBalancesCapital[socio] = balancesCapital[socio] + aportes[socio]
    
                        await db.collection('fondeos-capital').add({
                            descripcion: `gasto ${tipoGasto}. Por: ${gasto.cantidad}`,
                            socio,
                            cantidad: aportes[socio],
                            fecha
                        })
                    }
                }
                
                await db.collection('balances-saldo').add({
                    ...balancesSaldo,
                    ...nuevoBalancesSaldo,
                    fecha
                })
                await db.collection('balances-capital').add({
                    ...balancesCapital,
                    ...nuevoBalancesCapital,
                    cash: 0,
                    fecha
                })
                // setear porcentajes
                let capitalTotal = aportes.total
                socios.forEach(socio => {
                    capitalTotal+= balancesCapital[socio]
                })
                const nuevosPorcentajes = socios.reduce((objeto,socio) => {
                    return {
                        ...objeto,
                        [socio]: (balancesCapital[socio] + aportes[socio]) / capitalTotal
                    }
                }, {fecha})
                await db.collection('porcentajes').add(nuevosPorcentajes)
            }else{
                // no alcanza el cash capital
                // se toma el cash en capital y se completa de los saldos
                
                const cashRestante = gasto.cantidad - balancesCapital.cash
                const nuevoBalancesSaldo= {}
                const nuevoBalancesCapital = {}

                const aportes = calcularAportes({cantidad: cashRestante, balancesSaldo})
                for(let socio in sociosObjeto){
                    if(aportes[socio] > 0){
                        await db.collection('retiros-saldo').add({
                            descripcion: `gasto ${tipoGasto}. Por: ${gasto.cantidad} - Por completar: ${cashRestante}`,
                            socio,
                            cantidad: aportes[socio],
                            fecha
                        })
                        nuevoBalancesSaldo[socio] = balancesSaldo[socio] - aportes[socio]
                        nuevoBalancesCapital[socio] = balancesCapital[socio] + aportes[socio]
                        await db.collection('fondeos-capital').add({
                            descripcion: `gasto ${tipoGasto}. Por: ${gasto.cantidad} - Por completar: ${cashRestante}`,
                            socio,
                            cantidad: aportes[socio],
                            fecha
                        })
                    }
                }
                
                await db.collection('balances-saldo').add({
                    ...balancesSaldo,
                    ...nuevoBalancesSaldo,
                    fecha
                })
                await db.collection('balances-capital').add({
                    ...balancesCapital,
                    ...nuevoBalancesCapital,
                    cash: 0,
                    fecha
                })
                // setar porcentajes            
                let capitalTotal = aportes.total
                socios.forEach(socio => {
                    capitalTotal+= balancesCapital[socio]
                })
                const nuevosPorcentajes = socios.reduce((objeto,socio) => {
                    return {
                        ...objeto,
                        [socio]: (balancesCapital[socio] + aportes[socio]) / capitalTotal
                    }
                }, {fecha})
                await db.collection('porcentajes').add(nuevosPorcentajes)
            }

            if(tipoGasto === 'importacion'){
                gasto.disponible = true
            }
            await db.collection('gastos').add(gasto)
            resolve()
        }catch(error){
            reject(error)
        }
    })
}

export default registrarGasto