import React, {useEffect, useState} from 'react';

const FilaLote = ({producto, fila, handleChangeLote}) => {
    const [inputNombre, cambiarInputNombre] = useState('')
    const [inputCantidad, cambiarInputCantidad] = useState('')
    const [inputPrecioUnitario, cambiarInputPrecioUnitario] = useState('')
    const [inputPrecioVenta, cambiarInputPrecioVenta] = useState('')
    const [inputDescripcion, cambiarInputDescripcion] = useState('')
    useEffect(()=>{
        cambiarInputNombre(producto.nombre)
        cambiarInputCantidad(producto.cantidad)
        cambiarInputPrecioUnitario(producto.precioUnitario)
        cambiarInputPrecioVenta(producto.precioVenta? producto.precioVenta : '')
        cambiarInputDescripcion(producto.descripcion)
    },[producto])

    const handleChange = (e)=> {
        const campos = {
            nombre: inputNombre,
            cantidad: inputCantidad,
            precioUnitario: inputPrecioUnitario,
            precioVenta: inputPrecioVenta,
            descripcion: inputDescripcion
        }
        if(e.target.name === 'nombre'){
            campos.nombre = e.target.value
            cambiarInputNombre(campos.nombre)
        }else if(e.target.name === 'cantidad'){
            campos.cantidad = e.target.value.replace(/[^0-9.]/g, '')
            cambiarInputCantidad(campos.cantidad)
        }else if(e.target.name === 'precioUnitario'){
            campos.precioUnitario = e.target.value.replace(/[^0-9.]/g, '')
            cambiarInputPrecioUnitario(campos.precioUnitario)
        }else if(e.target.name === 'precioVenta'){
            campos.precioVenta = e.target.value.replace(/[^0-9.]/g, '')
            cambiarInputPrecioVenta(campos.precioVenta)
        }else if(e.target.name === 'descripcion'){
            campos.descripcion = e.target.value
            cambiarInputDescripcion(campos.descripcion)
        }
        let input = e.target.name
        const data = {
            [input]: campos[input]
        }
        handleChangeLote(data,fila)
    }

    return (
        <>
            <div className="row m-0 mb-1 fila_lote">
                <div className="col col-3 p-0 m-0">
                    <input
                        className="form-control"
                        type="text"
                        value={inputNombre}
                        onChange={handleChange} name="nombre"
                    />
                </div>
                <div className="col col-1 p-0 m-0">
                    <input
                        className="form-control"
                        type="text"
                        value={inputCantidad}
                        onChange={handleChange}
                        name="cantidad"
                    />
                </div>
                <div className="col col-1 p-0 m-0">
                    <input
                        className="form-control"
                        type="text"
                        value={inputPrecioUnitario}
                        onChange={handleChange}
                        name="precioUnitario"
                    />
                </div>
                <div className="col col-1 p-0 m-0">
                    <input
                        className="form-control"
                        disabled={true} type="text"
                        value={producto.costoUnitario? parseFloat(producto.costoUnitario).toFixed(2) : ''}
                        name="costoUnitario"
                    />
                </div>
                <div className="col col-1 p-0 m-0">
                    <input
                        className="form-control"
                        type="text"
                        value={inputPrecioVenta}
                        onChange={handleChange}
                        name="precioVenta"
                    />
                </div>
                <div className="col col-5 p-0 m-0">
                    <input
                        className="form-control"
                        type="text"
                        value={inputDescripcion}
                        onChange={handleChange}
                        name="descripcion"
                    />
                </div>
            </div> 
        </>
    );
}
 
export default FilaLote;