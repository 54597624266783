import React, {useState} from 'react';
import Alerta from '../elementos/Alerta';
import getUnixTime from 'date-fns/getUnixTime'
import registrarRetiroSaldo from '../firebase/registrarRetiroSaldo';
import { useBalances } from '../contextos/BalancesContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import formatearCantidad from '../funciones/convertirAMoneda'
import useObtenerPorcentajes from '../hooks/useObtenerPorcentajes';
import socios from './../socios'
import capitalize from './../funciones/capitalize'

const FormularioRetiro = () => {
    const MySwal = withReactContent(Swal)
    const [inputDescripcion, cambiarInputDescripcion] = useState('')
    const [inputCantidad, cambiarInputCantidad] = useState('')
    const [socio, cambiarSocio] = useState('')
    const [alerta, cambiarAlerta] = useState({})
    const [estadoAlerta, cambiarEstadoAlerta] = useState(false)

    const { balancesCapital, balancesSaldo } = useBalances()
    const [porcentajes] = useObtenerPorcentajes()

    const handleChange = (e) => {
        if(e.target.name === 'descripcion'){
            cambiarInputDescripcion(e.target.value)
        }else if(e.target.name === 'cantidad'){
            cambiarInputCantidad(e.target.value.replace(/[^0-9.]/g, ''))
        }
    }
    
    const handleChangeSocio = (e) => {
        cambiarSocio(e.currentTarget.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const cantidad = parseFloat(inputCantidad)
        if(inputDescripcion !== '' && inputCantidad !== '' && socio !== ''){
            if(cantidad <= (balancesSaldo[socio] + balancesCapital[socio]) && cantidad > 0 && cantidad <= (balancesSaldo[socio] + balancesCapital.cash)){
                const restarDeCapital = cantidad - balancesSaldo[socio]
                const restarAsocio = balancesCapital[socio] * (restarDeCapital / ((balancesCapital.cash + balancesCapital.stock) * porcentajes[socio]))

                MySwal.fire({
                    title: <p>Retiro a {socio.toUpperCase()}</p>,
                    html: <div>
                        <p className="fw-bold fs-2">{formatearCantidad(cantidad)}</p>
                        {
                            cantidad > balancesSaldo[socio] &&
                            <div className="text-start">
                                <p>Balance en saldo insuficiente</p>
                                <p>Deduccion de capital por: {formatearCantidad(restarAsocio)}</p>
                            </div>
                        }
                    </div>,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#157347',
                    cancelButtonColor: '#bb2d3b',
                    confirmButtonText: 'Ok!',
                    cancelButtonText: 'Cancelar',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return registrarRetiroSaldo({
                            descripcion: inputDescripcion,
                            socio,
                            cantidad,
                            fecha: getUnixTime(new Date()),
                            balancesSaldo,
                            balancesCapital,
                            porcentajes
                        })
                        .then((response)=> {
                            cambiarSocio('')
                            cambiarInputDescripcion('')
                            cambiarInputCantidad('')
                            return response
                        })
                        .catch((error) => {
                            console.log(error)
                            MySwal.showValidationMessage(
                                `Request failed: ${error}`
                            )
                        })
                    },
                    allowOutsideClick: () => !MySwal.isLoading(),
                    backdrop: true
                }).then((result) => {
                    if(result.isConfirmed){
                        return MySwal.fire({
                            html: <p>Retiro completado!</p>,
                            timer: 2000,
                            icon: 'success',
                            showConfirmButton: false,
                        })
                    }
                })
            }else{
                cambiarEstadoAlerta(true)
                cambiarAlerta({tipo: 'error', mensaje: 'El valor que ingresaste no es correcto'})
            }
        }else{
            cambiarEstadoAlerta(true)
            cambiarAlerta({tipo: 'error', mensaje: 'Por favor rellena todos los campos'})
        }

    }

    return (
        <>
            <div className="row">
                <div className="col col-sm-8 col-xl-6 mx-auto">
                    <h1 className="text-center mb-5">Registrar Retiro</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                            <select className="form-select"
                                onChange={handleChangeSocio}
                                value={socio}
                            >
                                <option value="">Seleccionar Socio</option>
                                {socios.map(socio => {
                                    return <option 
                                                key={socio}
                                                value={socio}
                                            >
                                                {capitalize(socio)}
                                            </option>
                                })}
                            </select>
                            <label>Socio</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Descripción"
                                name="descripcion"
                                value={inputDescripcion}
                                onChange={handleChange}
                            />
                            <label>Descripción</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="$0.00"
                                name="cantidad"
                                value={inputCantidad}
                                onChange={handleChange}
                            />
                            <label>Cantidad</label>
                        </div>
                        <div className="d-grid">
                            <button type="submit" className="btn p-3 btn-danger fw-bold">REGISTRAR</button>
                        </div>
                    </form>
                </div>
            </div>

            <Alerta
                tipo={alerta.tipo}
                mensaje={alerta.mensaje}
                estadoAlerta={estadoAlerta}
                cambiarEstadoAlerta={cambiarEstadoAlerta}
            />
        </>
    );
}

export default FormularioRetiro;