import {db} from './firebaseConfig'
import calcularAportes from '../funciones/calcularAportes'
import socios from '../socios'
const sociosObjeto = socios.reduce((objeto,socio) => {
    return {
        ...objeto,
        [socio]: socio
    }
}, {})

const registrarLote = ({lote, gastoImportacion, fecha, balancesSaldo, balancesCapital}) => {
    return new Promise(async (resolve, reject) => {
        try{
            let costoTotalLote = 0 // sin envio
            let costoRealLote = 0 // con envio
            lote.forEach( async (producto)=>{
                costoTotalLote += producto.precioUnitario * producto.cantidad
                costoRealLote += producto.costoUnitario * producto.cantidad
                producto.precioUnitario = parseFloat(producto.precioUnitario)
                producto.descripcion = { corta: producto.descripcion, completa: "" }
                producto.precioVenta = { normal: parseFloat(producto.precioVenta) }
                producto.tags = []
                producto.inStock = true
                producto.isActive = true
                await db.collection('productos').add({...producto, fecha})
            })
            
            if(balancesCapital.cash >= costoTotalLote){
                await db.collection('balances-capital').add({
                    ...balancesCapital,
                    stock: balancesCapital.stock + costoRealLote,
                    cash: balancesCapital.cash - costoTotalLote,
                    fecha
                })
            }else if(balancesCapital.cash === 0){
                const nuevoBalancesSaldo= {}
                const nuevoBalancesCapital = {}
                
                const aportes = calcularAportes({cantidad: costoTotalLote, balancesSaldo})

                for(let socio in sociosObjeto){
                    if(aportes[socio] > 0){
                        await db.collection('retiros-saldo').add({
                            descripcion: `Compra de lote. Por: ${costoTotalLote}`,
                            socio,
                            cantidad: aportes[socio],
                            fecha
                        })
                        nuevoBalancesSaldo[socio] = balancesSaldo[socio] - aportes[socio]
                        nuevoBalancesCapital[socio] = balancesCapital[socio] + aportes[socio]

                        await db.collection('fondeos-capital').add({
                            descripcion: `Compra de lote. Por: ${costoTotalLote}`,
                            socio,
                            cantidad: aportes[socio],
                            fecha
                        })
                    }
                }
                
                await db.collection('balances-saldo').add({
                    ...balancesSaldo,
                    ...nuevoBalancesSaldo,
                    fecha
                })
                await db.collection('balances-capital').add({
                    ...balancesCapital,
                    ...nuevoBalancesCapital,
                    stock: balancesCapital.stock + costoRealLote,
                    cash: 0,
                    fecha
                })
                // setear porcentajes
                let capitalTotal = aportes.total
                socios.forEach(socio => {
                    capitalTotal+= balancesCapital[socio]
                })
                const nuevosPorcentajes = socios.reduce((objeto,socio) => {
                    return {
                        ...objeto,
                        [socio]: (balancesCapital[socio] + aportes[socio]) / capitalTotal
                    }
                }, {fecha})
                await db.collection('porcentajes').add(nuevosPorcentajes)
            }else{
                const cashRestante = costoTotalLote - balancesCapital.cash
                const nuevoBalancesSaldo= {}
                const nuevoBalancesCapital = {}

                const aportes = calcularAportes({cantidad: cashRestante, balancesSaldo})

                for(let socio in sociosObjeto){
                    if(aportes[socio] > 0){
                        await db.collection('retiros-saldo').add({
                            descripcion: `Compra de lote. Por: ${costoTotalLote} - Por completar: ${cashRestante}`,
                            socio,
                            cantidad: aportes[socio],
                            fecha
                        })
                        nuevoBalancesSaldo[socio] = balancesSaldo[socio] - aportes[socio]
                        nuevoBalancesCapital[socio] = balancesCapital[socio] + aportes[socio]
                        await db.collection('fondeos-capital').add({
                            descripcion: `Compra de lote. Por: ${costoTotalLote} - Por completar: ${cashRestante}`,
                            socio,
                            cantidad: aportes[socio],
                            fecha
                        })
                    }
                }
                
                await db.collection('balances-saldo').add({
                    ...balancesSaldo,
                    ...nuevoBalancesSaldo,
                    fecha
                })
                await db.collection('balances-capital').add({
                    ...balancesCapital,
                    ...nuevoBalancesCapital,
                    stock: balancesCapital.stock + costoRealLote,
                    cash: 0,
                    fecha
                })
                // setar porcentajes
                let capitalTotal = aportes.total
                socios.forEach(socio => {
                    capitalTotal+= balancesCapital[socio]
                })
                const nuevosPorcentajes = socios.reduce((objeto,socio) => {
                    return {
                        ...objeto,
                        [socio]: (balancesCapital[socio] + aportes[socio]) / capitalTotal
                    }
                }, {fecha})
                await db.collection('porcentajes').add(nuevosPorcentajes)
            }

            await db.collection('gastos').doc(gastoImportacion.id).update({
                disponible: false
            })
            resolve()
        }catch(error){
            reject(error)
        }
    })
}

export default registrarLote