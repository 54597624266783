import { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const SearchBar = ({ onSearch }) => {
  const { location } = useHistory() 
  const [searh, setSearch] = useState(location.search.replace('?search=', '') || '')

  const handleSearch = e => {
    e.preventDefault()
    onSearch(searh.toLowerCase().trim())
  }

  return (
    <form onSubmit={handleSearch} className='w-100'>
      <Input>
        <input value={searh} onChange={(e) => setSearch(e.target.value)} type="text" placeholder="Ram, SSD, Audifonos" />
        <button type="submit">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 3.30252C14.0041 3.30252 17.25 6.54846 17.25 10.5525C17.25 12.2844 16.6427 13.8744 15.6295 15.1213L20.5303 20.0222C20.8232 20.3151 20.8232 20.7899 20.5303 21.0828C20.2641 21.3491 19.8474 21.3733 19.5538 21.1554L19.4697 21.0828L14.5688 16.182C13.3219 17.1952 11.7319 17.8025 10 17.8025C5.99594 17.8025 2.75 14.5566 2.75 10.5525C2.75 6.54846 5.99594 3.30252 10 3.30252ZM10 4.80252C6.82436 4.80252 4.25 7.37688 4.25 10.5525C4.25 13.7281 6.82436 16.3025 10 16.3025C13.1756 16.3025 15.75 13.7281 15.75 10.5525C15.75 7.37688 13.1756 4.80252 10 4.80252Z" fill="#212121"/>
          </svg>
        </button>
      </Input>
    </form>
  )
}

const Input = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid #212121;
  border-radius: 82px;
  padding: 0 10px;
  overflow: hidden;

  input {
    width: 100%;
    display: block;
    border: none;
    outline: 0;
    padding: 10px;
  }
  button {
    background: transparent;
    outline: 0;
    border: none;
  }
`
 
export default SearchBar;