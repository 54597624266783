import React, { useContext, useState, useEffect } from 'react';
import { db } from '../firebase/firebaseConfig'
import socios from '../socios'

const BalanceContext = React.createContext()

// useHook
const useBalances = () => useContext(BalanceContext)

const BalancesProvider = ({children}) => {
    const [balancesCapital, cambiarBalancesCapital] = useState()
    const [balancesSaldo, cambiarBalancesSaldo] = useState()
    
    useEffect(()=> {
        const DefaultBalance = socios.reduce((objeto,socio) => {
            return {
                ...objeto,
                [socio]: 0
            }
        }, {
            cash: 0,
            stock: 0
        })
        const cancelarSuscripcion = db.collection('balances-capital')
        .orderBy('fecha','desc')
        .limit(1)
        .onSnapshot((snapshop)=> {
            if(snapshop.docs.length > 0){
                cambiarBalancesCapital(snapshop.docs.map(documento => {
                    return {
                        ...documento.data()
                    }
                }))
            }else{
                cambiarBalancesCapital([DefaultBalance])
            }
        })

        return cancelarSuscripcion
    },[])

    useEffect(()=> {
        const DefaultBalance = socios.reduce((objeto,socio) => {
            return {
                ...objeto,
                [socio]: 0
            }
        }, {})
        const cancelarSuscripcion = db.collection('balances-saldo')
        .orderBy('fecha','desc')
        .limit(1)
        .onSnapshot((snapshop)=> {
            if(snapshop.docs.length > 0){
                cambiarBalancesSaldo(snapshop.docs.map(documento => {
                    return {
                        ...documento.data()
                    }
                }))
            }else{
                cambiarBalancesSaldo([DefaultBalance])
            }
        })

        return cancelarSuscripcion
    },[])

    return (
        <BalanceContext.Provider value={balancesCapital && balancesSaldo? {balancesCapital: balancesCapital[0], balancesSaldo: balancesSaldo[0]} : {}}>
            {balancesCapital && balancesSaldo && children}
        </BalanceContext.Provider>
    );
}
 
export {BalancesProvider, BalanceContext, useBalances};