export const customStylesTable = {
  headCells: {
    style: {
      backgroundColor: "rgb(33, 37, 41)",
      color: '#fff',
      '&:hover *': {
          color: '#fff'
      },
      '&:active *': {
          color: '#fff'
      }
    },
  },
  cells: {
    style: {
      fontSize: '16px',
      padding: '1.25rem 1rem',
      '& > div': {
        whiteSpace: 'normal !important'

      }
    }
  }
}