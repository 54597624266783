import {db} from './firebaseConfig'
import socios from '../socios'

const registrarFondeoCapital = ({socio, descripcion, cantidad, fecha, balancesCapital}) => {
    return new Promise(async (resolve, reject) => {
        try{
            await db.collection('fondeos-capital').add({
                descripcion,
                socio,
                cantidad,
                fecha
            })
            await db.collection('balances-capital').add({
                ...balancesCapital,
                [socio]: balancesCapital[socio] + cantidad,
                cash: balancesCapital.cash + cantidad,
                fecha
            })
            // setear porcentajes
            let capitalTotal = cantidad
            socios.forEach(socio => {
                capitalTotal+= balancesCapital[socio]
            })
            const nuevosPorcentajes = socios.reduce((objeto,socioCiclo) => {
                const sumar = socio === socioCiclo? cantidad : 0
                return {
                    ...objeto,
                    [socioCiclo]: (balancesCapital[socioCiclo] + sumar) / capitalTotal
                }
            }, {fecha})
            await db.collection('porcentajes').add(nuevosPorcentajes)
            resolve()
        }catch(error){
            reject(error)
        }
    })
}

export default registrarFondeoCapital