import { useState, useEffect } from 'react';
import { db } from './../firebase/firebaseConfig'

const useObtenerRetiros = () => {
  const [data, setData] = useState([])
  
  useEffect(()=> {
    // se ejecuta cada que hay un cambio en la base de datos
    const unsuscribe1 = db.collection('retiros-saldo')
    .onSnapshot((snapshop)=> {
      setData(state => state.concat(snapshop.docs.map((doc)=>{
        return {
          ...doc.data(),
          id: doc.id,
          tipo: 'retiros-saldo'
        }
      })))
    })

    const unsuscribe2 = db.collection('retiros-capital')
    .onSnapshot((snapshop)=> {
      setData(state => state.concat(snapshop.docs.map((doc)=>{
        return {
          ...doc.data(),
          id: doc.id,
          tipo: 'retiros-capital'
        }
      })))
    })

    // hay que desuscribirse 
    return () => {
      unsuscribe1()
      unsuscribe2()
    }
  }, [])

  return data;
}
 
export default useObtenerRetiros;