import {useState, useEffect} from 'react';
import { db } from '../firebase/firebaseConfig';

const useObtenerProductos = () =>{
    const [productos, cambiarProductos] = useState(null)
    
    useEffect(()=> {
        const subscripcion = db.collection('productos')
        .onSnapshot((snapshot) => {
            if(snapshot.docs.length > 0){
                cambiarProductos(snapshot.docs.map(producto => {
                    return {
                        ...producto.data(),
                        id:producto.id
                    }
                }))
            }else{
                cambiarProductos([])
            }
        })
        return subscripcion
    }, [])

    return productos
}

export default useObtenerProductos