import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Tags = () => {
  return (
    <ContainerTags>
      <Tag>
        <NavLink to='/tag/audifonos'>
          Audifonos
        </NavLink>
      </Tag>
      <Tag>
        <NavLink to='/tag/almacenamiento'>
          HDD/SSD
        </NavLink>
      </Tag>
      <Tag>
        <NavLink to='/tag/cables'>
          Cables
        </NavLink>
      </Tag>
      <Tag>
        <NavLink to='/tag/humidificadores'>
          Humidificadores
        </NavLink>
      </Tag>
      <Tag>
        <NavLink to='/tag/salud'>
          Salud
        </NavLink>
      </Tag>
    </ContainerTags>
  );
}

const ContainerTags = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const Tag = styled.div`
  a {
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
    color: black;
    &.active {
      text-decoration: underline;
    }
  }
`
 
export default Tags;