import useObtenerProductos from '../hooks/useObtenerProductos'
import { Link, useRouteMatch } from 'react-router-dom';
import formatearCantidad from '../funciones/convertirAMoneda'
import DataTable from 'react-data-table-component';
import { customStylesTable } from '../constants';
import { fromUnixTime } from 'date-fns';
import useObtenerGastos from '../hooks/useObtenerGastos';
import { useMemo } from 'react';

const ListaProductos = () => {
  let { path } = useRouteMatch();
  const productos = useObtenerProductos()
  const gastos = useObtenerGastos()
  const data = useMemo(() => {
    if (!productos || gastos.length === 0) return []
    let data = Object.entries(Object.groupBy(productos, ({ IDgastoImportacion }) => IDgastoImportacion))
    data.sort((a, b) => {
      const gastoA = gastos.find(g=> g.id === a[0])
      const gastoB = gastos.find(g=> g.id === b[0])

      return gastoA.fecha - gastoB.fecha
    })
    data.forEach(([, productos]) => {
      productos.sort((a, b) => b.cantidad - a.cantidad)
    })
    return data
  }, [productos, gastos])

  const columns = [
    {
      name: 'Nombre',
      selector: row => row.nombre,
      grow: 2
    },
    {
      name: 'Cantidad',
      selector: row => row.cantidad,
      grow: 1,
      sortable: true
    },
    {
      name: 'Costo unitario',
      selector: row => formatearCantidad(row.costoUnitario),
      grow: 1,
      sortable: true
    },
    {
      name: 'Precio venta',
      selector: row => formatearCantidad(row.precioVenta.normal),
      grow: 1,
      sortable: true
    },
    {
      name: 'Tags',
      selector: row => row.tags?.join(', '),
      grow: 1
    },
    {
      name: 'Descripción',
      selector: row => row.descripcion.corta,
      grow: 2
    },
    {
      name: '',
      cell: (row)=> {
        return (
          <div>
            <Link className="btn btn-primary" to={`${path}/editar/${row.id}`}>
              <i className="bi bi-pencil-square"></i>
            </Link>
            <Link className="btn btn-success ms-1" to={`${path}/vender/${row.id}`}>
              <i className="bi bi-currency-dollar"></i>
            </Link>
          </div>
        )
      },
      grow: 1
    },
  ]
  const conditionalRowStyles = [
    {
      when: row => row.cantidad === 0,
      style: {
        backgroundColor: '#f9b2a6'
      },
    },
    {
      when: row => !row.isActive,
      style: {
        backgroundColor: '#ccc'
      }
    },
  ];

  if (!productos || gastos.length === 0) return <h5 className="text-center">Cargando...</h5>
  if (productos && productos.length === 0) return <h5 className="text-center">No hay productos en stock</h5>

  return (
    data.map(([IDgastoImportacion, productos]) => (
      <div key={IDgastoImportacion}>
        <div className='p-2 bg-dark'>
          <h5 className='mb-0 text-white'>
            {(()=>{
              const gasto = gastos.find(g=> g.id === IDgastoImportacion)
              return `Lote ${fromUnixTime(gasto.fecha).toLocaleDateString('es-ES', {year: 'numeric', month: 'numeric', day: 'numeric' })}`
            })()}
          </h5>
        </div>
        <DataTable
          columns={columns}
          data={productos}
          customStyles={customStylesTable}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    ))
  )
}
 
export default ListaProductos;