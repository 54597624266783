import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// Check for environment variables to determine if emulators should be used
const useEmulators = process.env.REACT_APP_USE_EMULATORS === 'true'

let firebaseConfig = {};

if (useEmulators) {
  firebaseConfig = {
    // Use local emulator URLs for development
    apiKey: 'AIzaSy...', // Replace with your emulator API key
    authDomain: 'localhost:9099',
    projectId: 'project-id', // Replace with your project ID
    databaseURL: 'http://localhost:8080',
    storageBucket: 'localhost:9199',
    messagingSenderId: 'sender-id', // Optional
    appId: 'app-id', // Optional
  };
} else {
  firebaseConfig = {
    // Production configuration from environment variables
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    databaseURL: 'gs://proyecto-ventas-6243a.appspot.com/',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
  };
}

firebase.initializeApp(firebaseConfig);

// Connect to emulators if in use
if (useEmulators) {
  firebase.auth().useEmulator('http://localhost:9099');
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.storage().useEmulator('localhost', 9199);
}

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };