import {useState, useEffect} from 'react';
import { db } from '../firebase/firebaseConfig';
import socios from '../socios';

const useObtenerPorcentajes = () =>{
    const [porcentajes, cambiarPorcentajes] = useState([])
    
    useEffect(()=> {
        const DefaultPorcentajes = socios.reduce((objeto,socio) =>{
            return {
                ...objeto,
                [socio]: 0
            }
        }, {})
        const subscripcion = db.collection('porcentajes')
        .orderBy('fecha','desc')
        .limit(1)
        .onSnapshot((snapshop)=> {
            if(snapshop.docs.length > 0){
                cambiarPorcentajes(snapshop.docs.map(documento => {
                    return {
                        ...documento.data()
                    }
                }))
            }else{
                cambiarPorcentajes([DefaultPorcentajes])
            }
        })
        return subscripcion
    }, [])

    return porcentajes
}

export default useObtenerPorcentajes