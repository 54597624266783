import { Route, Switch, useRouteMatch } from "react-router-dom";
import FormularioGastos from "../../componentes/FormularioGastos";
import ListaGastos from "./components/ListaGastos";

const Gastos = () => {
  let { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ListaGastos} />
      <Route path={`${url}/registrar`} component={FormularioGastos} />
    </Switch>
  )
}
 
export default Gastos;