import React, {useState} from 'react';
import FilaLote from './FilaLote';
import useObtenerGastosImportacion from '../hooks/useObtenerGastosImportacion'
import fromUnixTime from 'date-fns/fromUnixTime'
import registrarLote from '../firebase/registrarLote';
import { useBalances } from '../contextos/BalancesContext';
import Alerta from '../elementos/Alerta';
import getUnixTime from 'date-fns/getUnixTime'
import formatearCantidad from '../funciones/convertirAMoneda'
import { useHistory } from 'react-router';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import calcularAportes from '../funciones/calcularAportes';
import socios from '../socios';
import capitalize from './../funciones/capitalize'

const FormularioLote = () => {
    const MySwal = withReactContent(Swal)
    const { balancesCapital, balancesSaldo } = useBalances()    
    const [alerta, cambiarAlerta] = useState({})
    const [estadoAlerta, cambiarEstadoAlerta] = useState(false)
    const [gastosImportacion] = useObtenerGastosImportacion()
    const [gastoImportacion, cambiarGastoImportacion] = useState(null)
    const [lote, cambiarLote] = useState([])
    const history = useHistory()
    
    const calcularCostoUnitario = (producto, costoTotalLote) =>{
        let costoTotalCantidad = parseInt(producto.cantidad) * parseFloat(producto.precioUnitario)
        let prorrateoPorCantidad = costoTotalCantidad * gastoImportacion.cantidad / costoTotalLote
        let costoUnitario = (prorrateoPorCantidad / parseInt(producto.cantidad)) + parseFloat(producto.precioUnitario)
        return parseFloat(parseFloat(costoUnitario).toFixed(2))
    }

    const handleChangeLote = (data,fila) => {
        const loteSinProrrateo = lote.map((producto,i)=>{
            if(i !== fila) return producto
            return {...producto,precioVenta: '',...data}
        })

        let costoTotalLote = 0
        loteSinProrrateo.forEach(producto=>{
            costoTotalLote += parseFloat(producto.precioUnitario) * parseInt(producto.cantidad)
        })

        const loteConProrrateo = loteSinProrrateo.map(producto=>{
            let precioVenta = producto.precioVenta !== ''? producto.precioVenta : calcularCostoUnitario(producto, costoTotalLote) * 1.8
            return {
                ...producto,
                precioVenta: parseFloat(precioVenta.toFixed(2)),
                cantidad: producto.cantidad? parseInt(producto.cantidad) : '',
                precioUnitario: producto.precioUnitario,
                costoUnitario: calcularCostoUnitario(producto, costoTotalLote),
                IDgastoImportacion: gastoImportacion.id
            }
        })
        cambiarLote(loteConProrrateo)
    }
    
    const handleChangeGastoImportacion = (e) => {
        if(e.currentTarget.value === '') return
        if(gastoImportacion && gastoImportacion.id === e.currentTarget.value) return
        const gasto = gastosImportacion.filter(gasto=> gasto.id === e.currentTarget.value)
        cambiarGastoImportacion(gasto[0])
        cambiarLote([{
            nombre: '',
            cantidad: '',
            precioUnitario: '',
            costoUnitario: '',
            precioVenta: '',
            descripcion: '',
            IDgastoImportacion: gasto[0].id
        }])
    }
    const addNuevaFila = ()=>{
        cambiarLote(lote.concat({
            nombre: '',
            cantidad: '',
            precioUnitario: '',
            costoUnitario: '',
            precioVenta: '',
            descripcion: '',
            IDgastoImportacion: gastoImportacion.id
        }))
    }
    
    const handleSubmit = (e)=>{
        e.preventDefault()
        let hayError = false
        let costoTotalLote = 0
        let costoRealLote = 0

        if(!gastoImportacion){
            hayError = true
            cambiarEstadoAlerta(true)
            cambiarAlerta({tipo: 'error',  mensaje: 'Debe seleccionar gasto de importacion'})
        }else{
            const campos = {
                nombre: 'Nombre',
                cantidad: 'Cantidad',
                precioUnitario: 'Precio unitario',
                precioVenta: 'Precio venta',
                descripcion: 'Descripcion',
            }
            lote.forEach((producto,fila)=>{
                for (const clave in producto) {
                    if((producto[clave] === '' || producto[clave] <= 0) && clave !== 'descripcion'){
                        hayError = true
                        cambiarEstadoAlerta(true)
                        cambiarAlerta({tipo: 'error', mensaje: `Error en producto #${fila+1}. Campo "${campos[clave]}" invalido`})
                    }
                }
            })

            lote.forEach((producto)=>{
                costoTotalLote += producto.precioUnitario * producto.cantidad
                costoRealLote += producto.costoUnitario * producto.cantidad
            })
            const totalCash = socios.reduce((acumulado,socio)=>{
                return acumulado + balancesSaldo[socio]
            }, balancesCapital.cash)

            if(costoTotalLote > totalCash){
                hayError = true
                cambiarEstadoAlerta(true)
                cambiarAlerta({tipo: 'error', mensaje: "Saldos insuficientes"})
            }
        }

        if(!hayError){
            let aportes
            let cashRestante

            if(balancesCapital.cash === 0){
                cashRestante = costoTotalLote
            }else if(balancesCapital.cash < costoTotalLote){
                cashRestante = costoTotalLote - balancesCapital.cash
            }
            if(balancesCapital.cash < costoTotalLote){
                aportes = calcularAportes({cantidad: cashRestante, balancesSaldo})
            }

            MySwal.fire({
                title: <p>Registro de lote</p>,
                html: <div>
                    <p className="fw-bold fs-2">{formatearCantidad(costoRealLote)}</p>
                    {
                        balancesCapital.cash < costoTotalLote &&
                        <div className="text-start">
                            <p className="text-danger">Cash insuficiente</p>
                            <p>Faltan: {formatearCantidad(cashRestante)}</p>
                            {
                                socios.map(socio => {
                                    return <p key={socio}>
                                        { aportes[socio] > 0 &&
                                        <>
                                            {capitalize(socio)} pone: {formatearCantidad(aportes[socio])}
                                        </>
                                        }
                                    </p>
                                })
                            }
                        </div>
                    }
                </div>,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#157347',
                cancelButtonColor: '#bb2d3b',
                confirmButtonText: 'Ok!',
                cancelButtonText: 'Cancelar',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return registrarLote({
                        lote,
                        gastoImportacion,
                        fecha: getUnixTime(new Date()),
                        balancesSaldo,
                        balancesCapital
                    })
                    .then((response)=> {
                        cambiarLote([])
                        cambiarGastoImportacion(null)

                        return response
                    })
                    .catch((error) => {
                        MySwal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    })
                },
                allowOutsideClick: () => !MySwal.isLoading(),
                backdrop: true
            }).then((result) => {
                if(result.isConfirmed){
                    return MySwal.fire({
                        html: <p>Lote registrado!</p>,
                        timer: 2000,
                        icon: 'success',
                        showConfirmButton: false,
                        didDestroy: ()=>{
                            history.push('/admin/inventario')
                        }
                    })
                }
            })
        }
    }

    return (
        <>
            {
                gastosImportacion && gastosImportacion.length > 0 &&
                <div className="row m-0" >
                    <form className="p-0" onSubmit={handleSubmit} >
                        <div className="d-flex justify-content-between mb-3">
                            <div className="form-floating">
                                <select className="form-select"
                                    onChange={handleChangeGastoImportacion}
                                    value={gastoImportacion? gastoImportacion.id : ''}
                                >
                                    <option disabled={gastoImportacion} value="">Seleccionar</option>
                                    {gastosImportacion.map(gasto => {
                                        return <option 
                                                    key={gasto.id}
                                                    value={gasto.id}
                                                >
                                                    {fromUnixTime(gasto.fecha).toLocaleDateString('es-ES', {year: 'numeric', month: 'numeric', day: 'numeric' })} - {formatearCantidad(gasto.cantidad)} - {gasto.descripcion}
                                                </option>
                                    })}
                                </select>
                                <label>Gasto importación</label>
                            </div>
                            <button className="btn btn-success fw-bold" type="submit">
                                REGISTRAR
                            </button>
                        </div>
                        
                        {
                            lote.length > 0 &&
                            <>
                                <div className="row mb-2 fw-bold">
                                    <div className="col col-3 text-center">Nombre</div>
                                    <div className="col col-1 text-center">Cantidad</div>
                                    <div className="col col-1 text-center">Valor *</div>
                                    <div className="col col-1 text-center">Costo *</div>
                                    <div className="col col-1 text-center">Precio *</div>
                                    <div className="col col-5 text-center">Descripción</div>
                                </div>

                                {
                                    lote.map((producto,i)=>{
                                        return  <FilaLote key={`fila${i}`} fila={i} producto={producto} handleChangeLote={handleChangeLote} />                      
                                    })
                                }
                                <button disabled={!gastoImportacion} className="btn btn-outline-secondary mt-3" type="button" onClick={addNuevaFila} >
                                    Nueva fila <i className="bi bi-plus-square"></i>
                                </button>
                            </>
                        }
                        {
                            lote.length === 0 &&
                            <h5 className="text-center">Selecione gasto de importación</h5>
                        }
                    </form>
                </div>
            }
            {
                gastosImportacion && gastosImportacion.length === 0 &&
                <h5 className="text-center">Debe registrar gasto de importación</h5>
            }

            {
                !gastosImportacion &&
                <h5 className="text-center">Cargando...</h5>
            }

            <Alerta
                tipo={alerta.tipo}
                mensaje={alerta.mensaje}
                estadoAlerta={estadoAlerta}
                cambiarEstadoAlerta={cambiarEstadoAlerta}
            />
        </>
    );
}

export default FormularioLote;