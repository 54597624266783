import React, {useState, useEffect} from 'react';
import Alerta from '../elementos/Alerta';
import { useHistory, useParams } from 'react-router';
import useObtenerProducto from '../hooks/useObtenerProducto';
import registrarVenta from '../firebase/registrarVenta';
import { useBalances } from '../contextos/BalancesContext';
import getUnixTime from 'date-fns/getUnixTime'
import formatearCantidad from './../funciones/convertirAMoneda';
import useObtenerPorcentajes from '../hooks/useObtenerPorcentajes';
import SocioCheckBox from '../elementos/SocioCheckBox';
import socios from '../socios';
import capitalize from './../funciones/capitalize'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const RegistrarVenta = () => {
    const MySwal = withReactContent(Swal)
    const { balancesCapital, balancesSaldo } = useBalances()
    const [inputPrecioVenta, cambiarInputPrecioVenta] = useState('')
    const [inputCantidad, cambiarInputCantidad] = useState(1)
    const [inputNota, cambiarInputNota] = useState('')
    const [alerta, cambiarAlerta] = useState({})
    const [estadoAlerta, cambiarEstadoAlerta] = useState(false)
    const {id} = useParams()
    const [producto] = useObtenerProducto(id)
    const history = useHistory()
    const [porcentajes] = useObtenerPorcentajes()
    const [vendedores, cambiarVendedores] = useState()
    
    useEffect(()=>{
        const sociosCheckBoxes = socios.reduce((objeto, socio) => {
            return {
                ...objeto,
                [socio]: socio === 'daniel'? false : true
            }
        }, {})
    
        cambiarVendedores(sociosCheckBoxes)
    },[])

    const handleCambiarVendedores = (vendedor) =>{
        cambiarVendedores({
            ...vendedores,
            [vendedor.socio]: vendedor.state
        })
    }

    useEffect(()=>{
        if(producto){
            const precio = producto.precioVenta
            cambiarInputPrecioVenta(precio.oferta || precio.normal)
        }
    },[producto])

    const handleChange = (e) => {
        if(e.target.name === 'precioVenta'){
            cambiarInputPrecioVenta(e.target.value.replace(/[^0-9.]/g, ''))
        }else if(e.target.name === 'cantidad'){
            cambiarInputCantidad(e.target.value.replace(/[^0-9.]/g, ''))
        }
        else if(e.target.name === 'nota'){
            cambiarInputNota(e.target.value)
        }
    }

    const handleSubmit = (e) =>{
        e.preventDefault()
        if(parseInt(inputCantidad) < 1 || parseInt(inputCantidad) > producto.cantidad){
            cambiarEstadoAlerta(true)
            cambiarAlerta({tipo: 'error', mensaje: `Campo cantidad invalido`})
        }else if(parseFloat(inputPrecioVenta) < producto.costoUnitario){
            cambiarEstadoAlerta(true)
            cambiarAlerta({tipo: 'error', mensaje: `Precio de venta invalido`})
        }else if(socios.every(socio => !vendedores[socio])){
            cambiarEstadoAlerta(true)
            cambiarAlerta({tipo: 'error', mensaje: `Falta vendedores`})
        }else{
            MySwal.fire({
                title: <p>Registrar venta</p>,
                html: <div className="text-start">
                    <p><b>Ganancia total:</b> {formatearCantidad((inputPrecioVenta * inputCantidad) - (producto.costoUnitario * inputCantidad))}</p>
                    <p><b>Ganancia porcentual:</b> {parseFloat(inputPrecioVenta/producto.costoUnitario * 100 - 100).toFixed(2)}%</p>
                    <p>{inputNota}</p>
                </div>,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#157347',
                cancelButtonColor: '#bb2d3b',
                confirmButtonText: 'Ok!',
                cancelButtonText: 'Cancelar',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return registrarVenta({
                        id,
                        producto: producto.nombre,
                        costoUnitario: producto.costoUnitario,
                        cantidad: parseInt(inputCantidad),
                        nota: inputNota,
                        productoCantidad: parseInt(producto.cantidad),
                        precio: parseFloat(inputPrecioVenta),
                        fecha: getUnixTime(new Date()),
                        balancesCapital,
                        balancesSaldo,
                        porcentajes,
                        vendedores
                    })
                    .then((response)=> {
                        cambiarInputPrecioVenta('')
                        cambiarInputCantidad(1)
                        cambiarInputNota('')
                        return response
                    })
                    .catch((error) => {
                        MySwal.showValidationMessage(
                            `Request failed: ${error}`
                        )
                    })
                },
                allowOutsideClick: () => !MySwal.isLoading(),
                backdrop: true
            }).then((result) => {
                if(result.isConfirmed){
                    return MySwal.fire({
                        html: <p>Venta registrada!</p>,
                        timer: 2000,
                        icon: 'success',
                        showConfirmButton: false,
                        didDestroy: ()=>{
                            history.push('/admin/inventario')
                        }
                    })
                }
            })
        }
    }

    return (
        <>  
            <div className="row">
                <div className="col col-sm-8 col-xl-6 mx-auto">
                    <h1 className="text-center mb-5">Registrar venta</h1>
                    {
                        producto &&
                        <>
                            <h5 className="text-center bg-dark p-2 text-light">{producto.nombre}</h5>
                            <div className="row mb-2">
                                <div className="col">
                                    <p><b>Disponibles:</b> {producto.cantidad}</p>
                                </div>
                                <div className="col text-end">
                                    <p><b>Costo:</b> {formatearCantidad(producto.costoUnitario)}</p>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-floating mb-3">
                                    <input
                                        type="number"
                                        min="1"
                                        max={producto.cantidad}
                                        className="form-control"
                                        placeholder="$0.00"
                                        name="cantidad"
                                        value={inputCantidad}
                                        onChange={handleChange}
                                    />
                                    <label>Cantidad</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Precio"
                                        name="precioVenta"
                                        value={inputPrecioVenta}
                                        onChange={handleChange}
                                    />
                                    <label>Precio venta</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nota"
                                        name="nota"
                                        value={inputNota}
                                        onChange={handleChange}
                                    />
                                    <label>Nota</label>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <span>Vendedores</span>
                                        {vendedores &&
                                            socios.map(socio => {
                                                return <div key={socio} className="form-check">
                                                    <SocioCheckBox
                                                        socio={socio}
                                                        state={vendedores[socio]}
                                                        changeState={handleCambiarVendedores}
                                                        porcentajes={porcentajes}
                                                    />
                                                    <label className="form-check-label" htmlFor={socio}>
                                                        {capitalize(socio)}
                                                    </label>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="col">
                                        <span className="d-block">Detalles</span>
                                        <p><b>Ganancia total:</b> {formatearCantidad((inputPrecioVenta * inputCantidad) - (producto.costoUnitario * inputCantidad))}</p>
                                        <p><b>Ganancia porcentual:</b> {parseFloat(inputPrecioVenta/producto.costoUnitario * 100 - 100).toFixed(2)}%</p>
                                    </div>
                                </div>
                                
                                <div className="d-grid">
                                    <button type="submit" className="btn p-3 btn-success fw-bold">REGISTRAR</button>
                                </div>
                            </form>
                        </>
                    }
                </div>
            </div>

            <Alerta
                tipo={alerta.tipo}
                mensaje={alerta.mensaje}
                estadoAlerta={estadoAlerta}
                cambiarEstadoAlerta={cambiarEstadoAlerta}
            />
        </>
    );
}
 
export default RegistrarVenta;