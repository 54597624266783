import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import App from './App';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {AuthProvider} from './contextos/AuthContext'
import {BalancesProvider} from './contextos/BalancesContext'
import RutaPrivada from './componentes/RutaPrivada'
import InicioSesion from './componentes/InicioSesion';
import { Helmet } from 'react-helmet';
import favicon from './imagenes/favicon.ico'
import Catalogo from './pages/catalogo/Catalogo';
import Detalles from './pages/detalles/Detalles';

const Index = () => {
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} type="image/x-icon"/>
        <title>FOXINN</title>
        <meta name="description" content="Tienda virtual de electrónicos y más" />
      </Helmet>

      <div className="container">
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Catalogo} />
              <Route exact path="/tag/:tag" component={Catalogo} />
              <Route path="/iniciar-sesion" component={InicioSesion} />
              <Route path='/producto/:id' component={Detalles} />
              
              <AuthProvider>
                <RutaPrivada path='/admin'>
                  <BalancesProvider>
                    {/* <ReparticionProvider> */}
                      <App />
                    {/* </ReparticionProvider> */}
                  </BalancesProvider>
                </RutaPrivada>
              </AuthProvider>
            </Switch>
          </BrowserRouter>
      </div>
    </>
  );
}

ReactDOM.render(<Index/> ,document.getElementById('root'));
