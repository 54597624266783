import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.css";
import "swiper/modules/pagination/pagination.min.css";
// import required modules
import { Pagination } from "swiper";

import formatearCantidad from "../../../funciones/convertirAMoneda";
import calDescuento from "../../../funciones/calDescuento";

const CardProduct = ({ producto }) => {
  return (
    <div className="card border-0 shadow-sm" style={{ borderRadius: '12px', background: '#F5F5F5', height: '100%', overflow: 'hidden' }}>
      <Swiper
        loop={true}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className={producto.inStock ? "mb-2" : "mb-2 disabled"}
      >
        {
          producto.imagenes?.map((image, i) => (
            <SwiperSlide key={i}>
              <div className="w-100 h-100">
                <img style={{ filter: !producto.inStock && 'grayscale(1)' }} src={image} className="img-fluid w-100" alt={producto.nombre} />
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
        
      <div className="card-body pt-0 p-2">
        <p className="card-title mb-1" style={{ fontWeight: 600 }}>{producto.nombre}</p>
        <p style={{ fontSize: '12px' }} className="card-text mb-1">{producto.descripcion.corta}</p>

        <small style={{ fontSize: '8px', opacity: !producto.precioVenta.oferta && 0 }} className='d-block fw-bold mb-1'>Antes: {formatearCantidad(producto.precioVenta.normal)} (Ahorras un {calDescuento(producto.precioVenta)})</small>
        <span className='fw-bold' style={{ color: producto.inStock ? '#F75C03' : '#656C73' }}>{formatearCantidad(producto.precioVenta.oferta || producto.precioVenta.normal)}</span>
      </div>
    </div>
  )
}
 
export default CardProduct;