import formatearCantidad from '../../../funciones/convertirAMoneda'
import fromUnixTime from 'date-fns/fromUnixTime'
import DataTable from 'react-data-table-component';
import useObtenerGastos from '../../../hooks/useObtenerGastos';
import { customStylesTable } from '../../../constants';

const ListaGastos = () => {
  const data = useObtenerGastos()

  const columns = [
    {
      name: 'Tipo',
      selector: row => row.tipoGasto,
      grow: 1,
      sortable: true
    },
    {
      name: 'Monto',
      selector: row => formatearCantidad(row.cantidad),
      grow: 1,
      sortable: true,
    },
    {
      name: 'Descripcion',
      selector: row => row.descripcion,
      grow: 1,
    },
    {
      name: 'Fecha',
      selector: row => fromUnixTime(row.fecha).toLocaleDateString('es-ES', {year: 'numeric', month: 'numeric', day: 'numeric' }),
      grow: 1,
      sortable: true,
      sortFunction: (rowA, rowB)=>{
        if (rowA.fecha > rowB.fecha) {
          return 1;
        } else if (rowB.fecha > rowA.fecha) {
          return -1;
        }
        return 0;
      }
    }
  ]

  return (
    <>
    {
      data.length > 0 &&
      <DataTable
        columns={columns}
        data={data}
        fixedHeader={true}
        fixedHeaderScrollHeight="80vh"
        customStyles={customStylesTable}
      />
    }
    {
      data.length === 0 &&
      <h5 className="text-center">No hay registros</h5>
    }
    </>
  );
}
 
export default ListaGastos;