import { useState, useEffect } from "react";
import { storage } from "../firebase/firebaseConfig";

const useGetGallery = () => {
  const [gallery, setGallery] = useState([])

  useEffect(() => {
    const get = async () => {
        const storageRef = storage.ref()
        // Create a reference under which you want to list
        const listRef = storageRef.child('img/');

        // Find all the prefixes and items.
        listRef.listAll()
        .then((res) => {
            res.items.forEach((itemRef) => {
            // All the items under listRef.
              itemRef.getDownloadURL()
              .then((url) => {
                  setGallery(state => [...state, url])
              })
              .catch((error) => {
                  // Handle any errors
                  console.error(error)
              });
            });
        }).catch((error) => {
            // Uh-oh, an error occurred!
            console.error(error)
        });
    }
    get()
  }, [])

  return gallery
}
 
export default useGetGallery;