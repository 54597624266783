import {useState, useEffect} from 'react';
import {db} from './../firebase/firebaseConfig'

const useObtenerGastosImportacion = () => {
    const [gastos, cambiarGastos] = useState()

    useEffect(()=> {
        // se ejecuta cada que hay un cambio en la base de datos
        const unsuscribe = db.collection('gastos')
        .where('tipoGasto', '==', 'importacion')
        .where('disponible', '==', true)
        .orderBy('fecha','desc')
        .onSnapshot((snapshop)=> {
            cambiarGastos(snapshop.docs.map((gasto)=>{
                return {
                    ...gasto.data(),
                    id: gasto.id
                }
            }))
        })

        // hay que desuscribirse 
        return unsuscribe
    }, [])

    return [gastos];
}
 
export default useObtenerGastosImportacion;