import React, {useState} from 'react';
import Alerta from '../elementos/Alerta';
import getUnixTime from 'date-fns/getUnixTime'
import registrarGasto from '../firebase/registrarGasto'
import { useBalances } from '../contextos/BalancesContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import formatearCantidad from '../funciones/convertirAMoneda'
import calcularAportes from '../funciones/calcularAportes';
import socios from '../socios';
import capitalize from './../funciones/capitalize'

const FormularioGastos = () => {
    const MySwal = withReactContent(Swal)
    const [inputDescripcion, cambiarInputDescripcion] = useState('')
    const [inputCantidad, cambiarInputCantidad] = useState('')
    const [tipoGasto, cambiarTipoGasto] = useState('importacion')
    const [alerta, cambiarAlerta] = useState({})
    const [estadoAlerta, cambiarEstadoAlerta] = useState(false)

    const { balancesCapital, balancesSaldo } = useBalances()
    // console.log('balances',balancesSaldo)
    const tiposDeGasto = [
        {id: 'importacion', texto: 'Gastos de Importacion'},
        {id: 'publicidad', texto: 'Gastos de Publicidad'},
        {id: 'otro', texto: 'Otro'}
    ]

    const handleChange = (e) => {
        if(e.target.name === 'descripcion'){
            cambiarInputDescripcion(e.target.value)
        }else if(e.target.name === 'cantidad'){
            cambiarInputCantidad(e.target.value.replace(/[^0-9.]/g, ''))
        }
    }
    
    const handleChangeTipoGasto = (e) => {
        cambiarTipoGasto(e.currentTarget.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const cantidad = parseFloat(inputCantidad)

        if(inputDescripcion !== '' && inputCantidad !== ''){
            const totalCash = socios.reduce((acumulado,socio)=>{
                return acumulado + balancesSaldo[socio]
            }, balancesCapital.cash)
            
            if(cantidad > 0 && cantidad <= totalCash){
                let aportes
                let cashRestante

                if(balancesCapital.cash === 0){
                    cashRestante = cantidad
                }else if(balancesCapital.cash < cantidad){
                    cashRestante = cantidad - balancesCapital.cash
                }
                if(balancesCapital.cash < cantidad){
                    aportes = calcularAportes({cantidad: cashRestante, balancesSaldo})
                }
                
                MySwal.fire({
                    title: <p>Gasto "{tipoGasto.toUpperCase()}"</p>,
                    html: <div>
                        <p className="fw-bold fs-2">{formatearCantidad(cantidad)}</p>
                        {
                            balancesCapital.cash < cantidad &&
                            <div className="text-start">
                                <p className="text-danger">Cash insuficiente</p>
                                <p>Faltan: {formatearCantidad(cashRestante)}</p>
                                {
                                    socios.map(socio => {
                                        return <p key={socio}>
                                            { aportes[socio] > 0 &&
                                            <>
                                                {capitalize(socio)} pone: {formatearCantidad(aportes[socio])}
                                            </>
                                            }
                                        </p>
                                    })
                                }
                            </div>
                        }
                    </div>,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#157347',
                    cancelButtonColor: '#bb2d3b',
                    confirmButtonText: 'Ok!',
                    cancelButtonText: 'Cancelar',
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        return registrarGasto({
                            descripcion: inputDescripcion,
                            tipoGasto,
                            cantidad,
                            fecha: getUnixTime(new Date()),
                            balancesSaldo,
                            balancesCapital
                        })
                        .then((response)=> {
                            cambiarTipoGasto('importacion')
                            cambiarInputDescripcion('')
                            cambiarInputCantidad('')
                            return response
                        })
                        .catch((error) => {
                            MySwal.showValidationMessage(
                                `Request failed: ${error}`
                            )
                        })
                    },
                    allowOutsideClick: () => !MySwal.isLoading(),
                    backdrop: true
                }).then((result) => {
                    if(result.isConfirmed){
                        return MySwal.fire({
                            html: <p>Gasto registrado!</p>,
                            timer: 2000,
                            icon: 'success',
                            showConfirmButton: false,
                        })
                    }
                })

                // registrarGasto({
                //     descripcion: inputDescripcion,
                //     tipoGasto,
                //     cantidad,
                //     fecha: getUnixTime(new Date()),
                //     balancesSaldo,
                //     balancesCapital
                // })
                // .then(()=> {
                //     cambiarTipoGasto('importacion')
                //     cambiarInputDescripcion('')
                //     cambiarInputCantidad('')
                    
                //     cambiarEstadoAlerta(true)
                //     cambiarAlerta({tipo: 'exito', mensaje: 'El gasto fue agregado correctamente'})
                // })
                // .catch((error) => {
                //     console.log(error)
                //     cambiarEstadoAlerta(true)
                //     cambiarAlerta({tipo: 'error', mensaje: 'Hubo un problema al intentar agregar el gasto'})
                // })
            }else{
                cambiarEstadoAlerta(true)
                cambiarAlerta({tipo: 'error', mensaje: 'El valor que ingresaste no es correcto'})
            }
        }else{
            cambiarEstadoAlerta(true)
            cambiarAlerta({tipo: 'error', mensaje: 'Por favor rellena todos los campos'})
        }

    }
    return (
        <>
            <div className="row">
                <div className="col col-sm-8 col-xl-6 mx-auto">
                    <h1 className="text-center mb-5">Registrar Gasto</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="form-floating mb-3">
                            <select className="form-select"
                                onChange={handleChangeTipoGasto}
                                value={tipoGasto}
                            >
                                {tiposDeGasto.map(gasto => {
                                    return <option 
                                                key={gasto.id}
                                                value={gasto.id}
                                            >
                                                {gasto.texto}
                                            </option>
                                })}
                            </select>
                            <label>Tipo de gasto</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Descripción"
                                name="descripcion"
                                value={inputDescripcion}
                                onChange={handleChange}
                            />
                            <label>Descripción</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="$0.00"
                                name="cantidad"
                                value={inputCantidad}
                                onChange={handleChange}
                            />
                            <label>Cantidad</label>
                        </div>
                        <div className="d-grid">
                            <button type="submit" className="btn p-3 btn-warning fw-bold">REGISTRAR</button>
                        </div>
                    </form>
                </div>
            </div>

            <Alerta
                tipo={alerta.tipo}
                mensaje={alerta.mensaje}
                estadoAlerta={estadoAlerta}
                cambiarEstadoAlerta={cambiarEstadoAlerta}
            />
        </>
    );
}
 
export default FormularioGastos;