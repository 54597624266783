import React, {useState} from 'react';
import { useBalances } from './../contextos/BalancesContext';
import formatearCantidad from './../funciones/convertirAMoneda';
import GraficoDona from './GraficoDona';
import socios from '../socios';
import capitalize from '../funciones/capitalize'

const Dashboard = () => {
    const [mostrarSaldos, cambiarMostrarSaldos] = useState(true)
    const { balancesCapital, balancesSaldo } = useBalances()
    
    const inversionTotal = socios.reduce((acumulado, socio) => {
      return acumulado + balancesCapital[socio]
    }, 0)

    const SaldosTotal = socios.reduce((acumulado, socio) => {
      return acumulado + balancesSaldo[socio]
    }, 0)

    const dataCapital = socios.reduce((data,socio) => {
      const newLabels = data.labels
      newLabels.push(capitalize(socio))
      const newData = data.data
      newData.push((balancesCapital.cash + balancesCapital.stock) * (balancesCapital[socio] / inversionTotal))
      return {
        labels: newLabels,
        data: newData
      }
    }, {
      labels: [],
      data: []
    })

    const dataSaldos = socios.reduce((data,socio) => {
      const newLabels = data.labels
      newLabels.push(capitalize(socio))
      const newData = data.data
      newData.push(balancesSaldo[socio])
      return {
        labels: newLabels,
        data: newData
      }
    }, {
      labels: [],
      data: []
    })

    return (
        <>
            <h1 className="text-center mb-3">RESUMEN</h1>

            <div className="row">
              <div className="col text-center">
                <GraficoDona data={dataCapital} titulo="Capital" type="capital" balancesCapital={balancesCapital} />
              </div>
              <div className="col text-center">
                <GraficoDona data={dataSaldos} titulo="Saldos" type="saldo" />
              </div>
            </div>

            <div className="balances row">
                <div className="col">
                    <h3 className="text-center">Stock: {formatearCantidad(balancesCapital.stock)}</h3>
                </div>
                <div style={{position: 'relative'}} className="col">
                    <div className="switch">
                      <p className="m-0">Mostrar Saldos</p>
                      <div className="switch-button">
                          <input
                            type="checkbox"
                            checked={mostrarSaldos}
                            onChange={()=> cambiarMostrarSaldos(!mostrarSaldos)}
                            name="switch-button"
                            id="switch-label"
                            className="switch-button__checkbox"
                          />
                          <label htmlFor="switch-label" className="switch-button__label"></label>
                      </div>
                    </div>
                    <h3 className="text-center">
                      {
                        !mostrarSaldos &&
                        <>
                          Cash: {formatearCantidad(balancesCapital.cash)}
                        </>
                      }
                      {
                        mostrarSaldos &&
                        <>
                          Cash: {formatearCantidad(balancesCapital.cash + SaldosTotal)}
                        </>
                      }
                    </h3>
                </div>
                <div className="col">
                    <h3 className="text-center">Total: {formatearCantidad(balancesCapital.cash + balancesCapital.stock)}</h3>
                </div>
                <hr />
            </div>
        </>
    );
}

export default Dashboard;