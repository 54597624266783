import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom'
import {auth} from '../firebase/firebaseConfig'
import Alerta from './../elementos/Alerta'

const InicioSesion = () => {
    const history = useHistory()
    const [correo, establecerCorreo] = useState('')
    const [password, establecerPassword] = useState('')
    const [estadoAlerta, cambiarEstadoAlerta] = useState(false)
    const [alerta, cambiarAlerta] = useState({})

    const handleChange = (e) => {
        if(e.target.name === 'email'){
            establecerCorreo(e.target.value)
        }else if(e.target.name === 'password'){
            establecerPassword(e.target.value)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        cambiarEstadoAlerta(false)
        cambiarAlerta({})

        const regexEmail = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/
        if(!regexEmail.test(correo)){
            cambiarEstadoAlerta(true)
            cambiarAlerta({
                tipo: 'error',
                mensaje: 'Por favor ingrese un correo valido.'
            })
            return
        }

        if(correo === '' || password === ''){
            cambiarEstadoAlerta(true)
            cambiarAlerta({
                tipo: 'error',
                mensaje: 'Por favor rellena todos los campos.'
            })
            return
        }

        try{
            await auth.signInWithEmailAndPassword(correo, password)
            history.push('/admin')
        }catch(error){
            let mensaje = ''
            switch(error.code){
				case 'auth/wrong-password':
					mensaje = 'La contraseña no es correcta.'
					break;
				case 'auth/user-not-found':
					mensaje = 'No se encontro ninguna cuenta con este correo electrónico.'
					break;
				default:
					mensaje = 'Hubo un error al intentar acceder a la cuenta.'
				break;
			}

            cambiarEstadoAlerta(true)
            cambiarAlerta({tipo: 'error',mensaje})
        }
    }

    return ( 
        <>
            <Helmet>
                <title>Iniciar Sesion</title>
            </Helmet>

            <div style={{height: '100vh'}} className="row align-items-center">
                <div className="col col-4 mx-auto">
                    <form onSubmit={handleSubmit} >
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Correo Electrónico"
                                value={correo}
                                onChange={handleChange}
                            />
                            <label>Correo Electrónico</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder="Contraseña"
                                value={password}
                                onChange={handleChange}
                            />
                            <label>Contraseña</label>
                        </div>   
                        <div className="d-grid mb-3">
                            <button className="btn btn-dark fw-bold p-3" type="submit">INICIAR SESION</button>
                        </div>
                    </form>
                </div>
            </div>

            <Alerta
                tipo={alerta.tipo}
                mensaje={alerta.mensaje}
                estadoAlerta={estadoAlerta}
                cambiarEstadoAlerta={cambiarEstadoAlerta}
            />
        </>
     );
}
 
export default InicioSesion;