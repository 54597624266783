import React from 'react';
import useObtenerVentas from '../hooks/useObtenerVentas';
import formatearCantidad from '../funciones/convertirAMoneda'
import fromUnixTime from 'date-fns/fromUnixTime'
import DataTable from 'react-data-table-component';
import { customStylesTable } from '../constants';

const HistorialDeVentas = () => {
    const ventas = useObtenerVentas()

    const columns = [
        {
            name: 'Producto',
            selector: row => row.producto,
            grow: 3
        },
        {
            name: 'Cantidad',
            selector: row => row.cantidad,
            grow: 0,
            sortable: true
        },
        {
            name: 'Costo',
            selector: row => formatearCantidad(row.costoUnitario),
            grow: 0,
            sortable: true
        },
        {
            name: 'Precio',
            selector: row => formatearCantidad(row.precio),
            grow: 0,
            sortable: true
        },
        {
            name: 'Ganancia',
            selector: row => parseFloat(row.precio/row.costoUnitario * 100 - 100).toFixed(2) + '%',
            grow: 0,
            sortable: true
        },
        {
            name: 'Fecha',
            selector: row => fromUnixTime(row.fecha).toLocaleDateString('es-ES', {year: 'numeric', month: 'numeric', day: 'numeric' }),
            grow: 0,
            sortable: true,
            sortFunction: (rowA, rowB)=>{
                if (rowA.fecha > rowB.fecha) {
                    return 1;
                } else if (rowB.fecha > rowA.fecha) {
                    return -1;
                }
                return 0;
            }
        },
        {
            name: 'Vendedores',
            selector: row => row.vendedores.replace(/ /g,'-'),
            grow: 2
        },
        {
            name: 'Nota',
            selector: row => row.nota,
            grow: 6
        },
    ]

    return (
        <>
        {
            ventas.length > 0 &&
            <DataTable
                columns={columns}
                data={ventas}
                fixedHeader={true}
                fixedHeaderScrollHeight="80vh"
                customStyles={customStylesTable}
            />
        }
        {
            ventas.length === 0 &&
            <h5 className="text-center">No hay registros</h5>
        }
        </>
    );
}
 
export default HistorialDeVentas;