import React from 'react';

const SocioCheckBox = ({socio, state, changeState, porcentajes}) => {
    return (
        <input className="form-check-input"
            type="checkbox"
            id={socio}
            checked={state}
            onChange={()=> changeState({socio, state:!state})}
            disabled={porcentajes? porcentajes[socio] > 0? false : true :false}
        />
    );
}
 
export default SocioCheckBox;