import {useState, useEffect} from 'react';
import { db } from '../firebase/firebaseConfig';

const useObtenerVentas = () =>{
    const [ventas, cambiarVentas] = useState([])
    
    useEffect(()=> {
        const subscripcion = db.collection('ventas')
        .orderBy('fecha','desc')
        .onSnapshot((snapshot) => {
            if(snapshot.docs.length > 0){
                cambiarVentas(snapshot.docs.map(venta => {
                    return {
                        ...venta.data(),
                        id: venta.id
                    }
                }))
            }
        })
        return subscripcion
    }, [])

    return ventas
}

export default useObtenerVentas