import { Route, Switch, useRouteMatch } from "react-router-dom";
import FormularioRetiro from "../../componentes/FormularioRetiro";
import ListaRetiros from "./components/ListaRetiros";

const Retiros = () => {
  let { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ListaRetiros} />
      <Route path={`${url}/registrar`} component={FormularioRetiro} />
    </Switch>
  )
}
 
export default Retiros;