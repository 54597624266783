import IconIg from './../../../imagenes/icon-instagram.svg'
import IconWs from './../../../imagenes/icon-ws.svg'
import logo from './../../../imagenes/logo-full.png'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div className="row p-2 mb-2" style={{ background: '#04052E' }}>
      <div className="col">
        <Link to='/'>
          <img src={logo} alt="logo" className="img-fluid" />
        </Link>
      </div>
      <div className="col">
        <div className="row ms-auto">
          <div className='col p-0 d-flex justify-content-end'>
            <a href="https://wa.me/message/LIM7S3JBRSZEM1" target="_blank" rel="noreferrer">
              <img src={IconWs} alt="Whatsapp" title="contactar por Whatsapp" />
            </a>
          </div>
          <div className='col p-0 d-flex justify-content-end flex-grow-0 ms-2'>
            <a href="https://www.instagram.com/foxinnve/" target="_blank" rel="noreferrer">
              <img src={IconIg} alt="Instagram" title="ver perfil de Instagram" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
 
export default Header;