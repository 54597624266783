import { Route, Switch, useRouteMatch } from "react-router-dom";
import ListaFondeos from "./components/ListaFondeos";
import FormularioFondeo from "../../componentes/FormularioFondeo";

const Fondeos = () => {
  let { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ListaFondeos} />
      <Route path={`${url}/registrar`} component={FormularioFondeo} />
    </Switch>
  )
}
 
export default Fondeos;