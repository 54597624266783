import React from 'react';
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import socios from '../socios';

const GraficoDona = ({data,titulo, type, balancesCapital=null}) => {
    return ( 
        <Grafico>
            <h4 className="text-center">{titulo}</h4>
            <Doughnut
                data={{
                    labels: data.labels,
                    datasets: [{
                      data: data.data,
                      backgroundColor: [
                        '#00246E',
                        '#F2CB05',
                        '#00012A',
                      ],
                      hoverOffset: 4
                    }]
                }}
                options={{
                plugins: {
                    legend: {
                    position: 'bottom'
                    },
                    tooltip: {
                    bodyFont: {
                        size: 30,
                        weight: 'bold'
                    },
                    callbacks: {
                        label: function(context) {
                            var label = context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed !== null) {
                                if(type === 'saldo'){
                                    label += ' ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed) 
                                }else if(type === 'capital'){
                                    const inversionTotal = socios.reduce((acumulado, socio) => {
                                        return acumulado + balancesCapital[socio]
                                    }, 0)
                                    label += ' ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed) + ' | ' + parseFloat( balancesCapital[context.label.toLowerCase()] / inversionTotal * 100).toFixed(2) + ' %' 
                                }
                            }
                            return label;
                        }
                    }
                    }
                }
                }}
            />
        </Grafico>
     );
}

const Grafico = styled.div`
  display: inline-block;
  width: 45vmin;
`

export default GraficoDona;