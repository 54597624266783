import useObtenerProducto from "../../hooks/useObtenerProducto";
import { useHistory, useParams } from "react-router-dom";
import Header from "../catalogo/components/Header";
import SearchBar from "../catalogo/components/SearchBar";
import calDescuento from './../../funciones/calDescuento';
import formatearCantidad from "./../../funciones/convertirAMoneda";
import { Swiper, SwiperSlide } from "swiper/react";
import IconWs from './../../imagenes/icon-ws.svg'

// Import Swiper styles
import "swiper/swiper.css";
import "swiper/modules/pagination/pagination.min.css";
// import required modules
import { Pagination, Autoplay } from "swiper";
import styled from "styled-components";

const Detalles = () => {
  const {id} = useParams()
  const [producto] = useObtenerProducto(id)
  const history = useHistory()

  const handleGo = (product) => {
    const msg = encodeURIComponent(`Hola! estoy interesado en este producto *${product}*`)
    window.open(`https://api.whatsapp.com/send?phone=+584149984740&text=${msg}`, '_blank');
  }

  const handleSearch = (keyword) => {
    if (keyword) {
      history.push(`/?search=${keyword}`)
    } else {
      history.goBack()
    }
  }

  if (!producto) return null
  return (
    <Page>
      <Header />
      <div className="d-flex w-100 mb-3">
        <button onClick={() => history.goBack()} className="btn btn-outline">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7327 19.7905C11.0326 20.0762 11.5074 20.0646 11.7931 19.7647C12.0788 19.4648 12.0672 18.99 11.7673 18.7043L5.51587 12.7497H20.25C20.6642 12.7497 21 12.4139 21 11.9997C21 11.5855 20.6642 11.2497 20.25 11.2497H5.51577L11.7673 5.29502C12.0672 5.00933 12.0787 4.5346 11.7931 4.23467C11.5074 3.93475 11.0326 3.9232 10.7327 4.20889L3.31379 11.2756C3.14486 11.4365 3.04491 11.6417 3.01393 11.8551C3.00479 11.9019 3 11.9503 3 11.9997C3 12.0493 3.00481 12.0977 3.01398 12.1446C3.04502 12.3579 3.14496 12.563 3.31379 12.7238L10.7327 19.7905Z" fill="#212121"/>
          </svg>
        </button>
        <SearchBar onSearch={handleSearch} />
      </div>
      <h1 className="p-title">{producto.nombre}</h1>

      <Swiper
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false
        }}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination, Autoplay]}
        className={producto.inStock ? "mb-3 details-page" : "mb-3 disabled details-page"}
      >
        {
          producto.imagenes.map((image, i) => (
            <SwiperSlide key={i}>
              <div className="w-100 h-100">
                <img style={{ filter: !producto.inStock && 'grayscale(1)' }} src={image} className="img-fluid w-100" alt={producto.nombre} />
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
      
      <p className="oferta-label" style={{ opacity: !producto.precioVenta.oferta && 0 }}>Antes: <span className="old-price">{formatearCantidad(producto.precioVenta.normal)}</span> (ahorras un {calDescuento(producto.precioVenta)})</p>
      <div className="price-box">
        <span className="price">{formatearCantidad(producto.precioVenta.oferta || producto.precioVenta.normal)}</span>
        {
          producto.precioVenta.oferta &&
            <span className="tag">REBAJA</span>
        }
      </div>
      <button onClick={() => handleGo(producto.nombre)} className="button-ws shadow-sm mb-3">
        <div></div>
        <span>Pedir por whatsapp</span>
        <div>
          <img src={IconWs} alt="icon" />
        </div>
      </button>
      <div className="descripcion mb-3">
        <h6>Descripción:</h6>
        <p>{producto.descripcion.completa}</p>
      </div>
    </Page>
  )
}

const Page = styled.div`
  max-width: 500px;
  margin: auto;

  .p-title{
    font-size: 20px
  }
  .oferta-label {
    color: #495057;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
    .old-price {
      color: #D11616;
    }
  }
  .price-box{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;

    .price {
      font-size: 2rem;
      font-weight: bolder;
    }

    .tag {
      font-size: 10px;
      font-weight: bolder;
      background: #1F2066;
      padding: 5px 12px;
      border-radius: 16px;
      color: #fff;
      margin: 0;
      line-height: 1;
    }
  }

  .button-ws {
    display: flex;
    width: 100%;
    background: #04052E;
    font-weight: bold;
    color: #fff;
    justify-content: space-between;
    padding: 10px;
    border-radius: 8px;
    text-transform: uppercase;
    overflow: hidden;

    div {
      width: 24px;
      img {
        width: 24px;
      }
    }
  }

  .descripcion {
    padding: 1rem 0 0;
    border-top: 2px solid #D9D9D9;
    border-bottom: 2px solid #D9D9D9;
  }
`

export default Detalles;